import request from '@/plugins/axios'

// S 设备类型管理

// 获取设备分类列表
export const apiDeviceCategoryList = (params: any) =>
  request.get('/device_category.deviceCategory/getDeviceCategoryList', {
    params,
  })
// 分类详情
export const apiDeviceCategoryDetail = (id: number) =>
  request.get('/device_category.deviceCategory/getInfoCategory/', {
    params: { id },
  })
// 设备分类状态调整
export const apiDeviceCategoryStatus = (data: any) =>
  request.post('/device_category.deviceCategory/changeStatus', data)
// 设备分类删除
export const apiDeviceCategoryDel = (id: number) =>
  request.post('/device_category.deviceCategory/delete', { id })
// 新增设备分类
export const apiDeviceCategoryAdd = (data: any) =>
  request.post('/device_category.deviceCategory/addDeviceCategory', data)
// 编辑保存
export const apiDeviceCategoryEdit = (data: any) =>
  request.post('/device_category.deviceCategory/editDeviceCategory/', data)

// E 设备类型管理

// S 光场设备管理

// 获取新增设备的编号
export const apiDeviceNumber = (params: any) =>
  request.get('/device.device/getDeviceNumber', { params })
// 设备列表
export const apiDeviceList = (params: any) =>
  request.get('/device.device/getPageList', { params })
// 新增设备
export const apiDeviceAdd = (data: any) =>
  request.post('/device.device/create', data)
// 新增光场设备-设备基础信息-设备分类
export const apiGetDeviceCategory = (params: any) =>
  request.get('/device_category.deviceCategory/getDeviceCategory', {
    params,
  })
// 设备详情
export const apiDeviceDetail = (device_id: number) =>
  request.get('/device.device/getDeviceInfo/', { params: { device_id } })
// 编辑数据保存
export const apiDeviceEdit = (data: any) =>
  request.post('/device.device/editDevice/device_id/' + data.id, data)
// 设备删除
export const apiDeviceDel = (id: number) =>
  request.post('/device.device/delete', { id })
// 生成预览的二维码
export const apiDevicePreview = (id: number) =>
  request.post('/device.device/previewDevice/device_id/' + id)

// E 光场设备管理

// S 光场素材

export const apiSaveOpticalFieldMaterial = (data: any) =>
  request.post('/upload/saveLightFile', data)

export const apiRecycleBinList = (params: any) =>
  request.get('/recycleFile/recycleList', { params })

export const apiRecycleBinReset = (data: { ids: any[] }) =>
  request.post('/recycleFile/resetFile', data)

export const apiRecycleBinDel = (data: { id: any }) =>
  request.post('/recycleFile/realDel', data)

// E 光场素材

// S 轮播图

// 获取轮播图列表
export const apiBannerList = (params: any) =>
  request.get('/settings.banner.banner/index', { params })

// 新增轮播图
export const apiBannerAdd = (data: any) =>
  request.post('/settings.banner.banner/add', data)

// 轮播图详情
export const apiBannerDetail = (id: number) =>
  request.get('/settings.banner.banner/info', { params: { id } })

// 编辑轮播图数据保存
export const apiBannerEdit = (data: any) =>
  request.post('/settings.banner.banner/edit', data)

// 删除轮播图
export const apiBannerDel = (id: number) =>
  request.post('/settings.banner.banner/delete', { id })

// 修改轮播图显示效果
export const apiBannerStatus = (data: any) =>
  request.post('/settings.banner.banner/changeShow', data)

// E 轮播图

// S 设备插件
// 获取设备插件列表
export const apiGetPluginList = (data: any) =>
  request.post('/soft.soft/getPageList', data)
// 删除设备插件
export const apiDeletePlugin = (data: any) =>
  request.post('/soft.soft/delSoft', data)
// 修改设备插件状态
export const apiChangePluginStatus = (data: any) =>
  request.post('/soft.soft/updateStatus', data)
// 发布设备插件
export const apiPublishPlugin = (data: any) =>
  request.post('/soft.soft/addSource', data)
// 编辑设备插件
export const apiEditPlugin = (data: any) =>
  request.post('/soft.soft/editSource', data)
// 获取设备插件详情
export const apiPluginDetail = (data: any) =>
  request.post('/soft.soft/getSoftInfo', data)

// E 设备插件
