























































































import {Component, Prop, Vue} from 'vue-property-decorator'
import MaterialSelect from '@/components/material-select/index.vue'
import BtnUpload from '@/components/upload/BtnUpload.vue'

@Component({
  components: {
    MaterialSelect, BtnUpload
  },
})
export default class AddDeviceInfo extends Vue {
  @Prop() value: any
  @Prop({default: () => ({})}) lists: any
  form = {}

  get deviceCategoryList() {
    return this.lists || []
  }

  handleSuccess(response: any) {
    const {url} = response
    this.value.mask = url
    console.log(this.value.mask,"handleSuccess")
  }

  created() {
  }
}
