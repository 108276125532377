

























import {Component, Prop, Vue} from 'vue-property-decorator'
import config from '@/config'

enum Image {
  png = 'image/png',
  jpg = 'image/jpeg'
}

@Component
export default class BtnUpload extends Vue {
  @Prop({default: 1}) limit!: number
  @Prop({default: true}) multiple!: boolean
  @Prop() type!: any
  @Prop() data!: any
  @Prop() prefix!: any // 前缀
  @Prop({default: '点击上传'}) name!: any
  action = `${config.baseURL}/adminapi/upload/lightFile`;
  version = config.version;

  async uploadFile(file: any) {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const OSS = require('ali-oss')
    const client = new OSS({
      accessKeyId: 'LTAI5t6CTbjsxAa1kz4x3RCp',
      accessKeySecret: 'eNlsZparfM5VkW3OSQJ9MkbH0UJH8D',
      // stsToken: '',
      region: 'oss-cn-qingdao',
      bucket: 'imagetru3d'
    })
    // 获取要上传的文件
    // 获取扩展名
    const suffix = file.file.name.split('.').pop()
    const uuid = new Date().getTime() + Math.random().toString(36).substr(2)
    const path = 'uploads/light/' + this.prefix + '_' + uuid + '.' + suffix
    const res = await client.multipartUpload(path, file.file)
    return {
      res,
      file
    }
  }

  // 上传成功
  handleSuccess(response: any) {
    const data = {url: response.res.res.requestUrls[0].split('?')[0]}
    this.$emit('on-success', data)
  }

  // 超出上传数量
  handleExceed(files: any, fileList: any) {
    this.$message.error(`当前只能上传${this.limit}个文件哦！`)
  }

  // 上传之前
  beforeUpload(file: any) {
     
    //获取文件名称，在进行判断传输的类型,进行规则验证

    // 如果组件没有写type，默认任何类型都可上传
    if (!this.type) {
      return true
    }
    // 如果写了类型则判断上传类型是否正确
    switch (this.type) {
      case 'Image':
        if (file.type !== Image.png && file.type !== Image.jpg) {
          this.$message.error('您上传的图片类型不受支持！')
          return false
        }
        break
    }
  }
}
