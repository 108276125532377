













































import { Component, Prop, Vue } from 'vue-property-decorator'
import PopoverInput from '@/components/popover-input.vue'
import MaterialSelect from '@/components/material-select/index.vue'
@Component({
  components: {
    MaterialSelect,
    PopoverInput,
  },
})
export default class AddHardware extends Vue {
  @Prop() value: any
  @Prop() hardwareSpecData!: any
  mounted() {}

  get sepcItem() {
    return this.value.hardware_spec_item
  }

  // 判空的方法
  // checkEmpty(obj) {
  //   if (typeof obj === 'undefined' || obj == null || obj === '') {
  //     return this.$message.error('参数不能为空')
  //   }
  // }

  // 添加参数项
  addSpecItem() {
    this.sepcItem.push({
      spec_type: 0,
      spec_name: '',
      spec_value: '',
    })
  }

  // 移除参数项
  removeSpecItem(index: number) {
    if (this.sepcItem.length <= 1)
      return this.$message.error('至少有一个参数项')
    this.sepcItem.splice(index, 1)
  }

  checkValue(index: number) {
    const name = this.sepcItem[index].spec_name
    this.sepcItem.forEach((item: any, idx: number) => {
      if (item.spec_name && index != idx && item.spec_name == name) {
        this.sepcItem[index].spec_name = ''
        this.$message({
          message: '已存在相同规格值',
          type: 'warning',
        })
      }
    })
  }
}
