
































































import {Component, Vue, Watch} from 'vue-property-decorator'
import AddDeviceBasic from '@/components/imagetru3d/add-device-basic.vue'
import AddHardware from '@/components/imagetru3d/add-hardware.vue'
import AddVirtualCamera from '@/components/imagetru3d/add-virtual-camera.vue'
import {
  apiGetDeviceCategory,
  apiDeviceAdd,
  apiDeviceDetail,
  apiDeviceEdit,
  apiDeviceNumber,
} from '@/api/imagetru3d'

@Component({
  components: {
    AddDeviceBasic,
    AddHardware,
    AddVirtualCamera,
  },
})
export default class DeviceEdit extends Vue {
  $refs!: { form: any }
  // 设备 id
  id!: any
  // 默认不加载页面
  loading = true
  // 默认界面
  activeName = 'deviceBasic'
  // 用于存储获取到的设备分类列表
  deviceCategoryList = []

  // 必填字段与对应的选项卡，为了实现规则验证
  requireFields = [
    {
      fields: [
        'device_name',
        'device_number',
        'device_size',
        'category_id',
        // 'note',
        'device_img',
      ],
      activeName: 'deviceBasic',
    },
  ]

  // 表单
  // 设备基础信息页表单
  form: any = {
    // 设备名称
    device_name: '',
    // 设备型号
    device_number: '',
    // 设备尺寸类型
    device_size_type: 15.6,
    // 自定义设备尺寸
    device_size: '',
    // 设备类型
    category_id: '',
    // 设备备注
    note: '',
    mask: '',
    // 分类图片
    device_img: '',
  }

  // 与硬件相关的参数，绑定的数据
  hardwareSpecData: any = {
    hardware_spec_item: [],
  }

  // 与虚拟相机相关的参数，绑定的数据
  virtualCameraSpecData: any = {
    virtualCamera_spec_item: [],
  }

  // 规则验证
  rules = {
    device_name: [
      {
        required: true,
        message: '请输入设备名称',
        trigger: ['blur', 'change'],
      },
    ],
    // device_number: [
    //   {
    //     required: true,
    //     message: '请输入设备型号',
    //     trigger: ['blur', 'change'],
    //   },
    // ],
    // note: [
    //   {
    //     required: true,
    //     message: '请输入设备备注',
    //     trigger: ['blur', 'change'],
    //   },
    // ],
    category_id: [
      {
        required: true,
        message: '请选择分类',
        trigger: ['blur', 'change'],
      },
    ],
    device_img: [
      {
        required: true,
        message: '请选择设备图片',
        trigger: ['blur', 'change'],
      },
    ], mask: [{
      required: true,
      message: '请上传蒙版',
      trigger: ['blur', 'change']
    }]
  }

  @Watch('form', {deep: true})
  formChange(val: any) {
    console.log(val)
  }

  @Watch('hardwareSpecData', {deep: true})
  hardwareSpecDataChange(val: any) {
    this.form.hardware_spec_item = val.hardware_spec_item
  }

  @Watch('virtualCameraSpecData', {deep: true})
  virtualCameraSpecDataChange(val: any) {
    this.form.virtualCamera_spec_item = val.virtualCamera_spec_item
  }

  // 设备详情
  getDeviceDetail() {
    apiDeviceDetail(this.id).then((res: any) => {
      this.initHardwareSpecData(res.device_spec)
      this.initVirtualCameraSpecData(res.device_spec)
      this.form = res
      this.form.device_size_type = parseFloat(res.device_size_type)
    })
  }

  // 获取设备分类列表
  getDeviceCategoryList(): void {
    apiGetDeviceCategory({pager_type: 1}).then((res: any) => {
      this.deviceCategoryList = res.lists
    })
  }

  // “上一步”和“下一步”
  onNextStep(isNext = true) {
    switch (this.activeName) {
      case 'deviceBasic':
        this.activeName = 'hardware'
        break
      case 'hardware':
        this.activeName = isNext ? 'virtualCamera' : 'deviceBasic'
        break
      case 'virtualCamera':
        this.activeName = 'hardware'
        break
    }
  }

  // 新增设备
  handleSave() {
    if (this.form.device_size_type != 0) {
      this.form.device_size = this.form.device_size_type
    }

    var sendData = this.form

    sendData.device_spec = {
      hardware_spec_item: this.form.hardware_spec_item,
      virtualCamera_spec_item: this.form.virtualCamera_spec_item,
    }

    this.$refs.form.validate((valid: boolean, object: any) => {
      if (valid) {
        const loading = this.$loading({
          lock: true,
          text: '保存中...',
          spinner: 'el-icon-loading',
        })
        const api = this.id ? apiDeviceEdit(sendData) : apiDeviceAdd(sendData)
        api
            .then(() => {
              this.$router.go(-1)
            })
            .finally(() => {
              loading.close()
            })
      } else {
        const fieldsitem = this.requireFields.find((item) => {
          for (let value of item.fields) {
            if (object[value]) {
              this.$message.error(object[value][0].message)
              return true
            }
          }
        })
        fieldsitem && (this.activeName = fieldsitem.activeName)
        return false
      }
    })
  }

  // 初始化与硬件相关的参数
  initHardwareSpecData(data: any = {}) {
    const hardware_spec_item = [
      {
        spec_type: 0,
        // 规格名
        spec_name: '',
        // 规格值
        spec_value: '',
      },
    ]
    const specData: any = {
      hardware_spec_item: data.hardware_spec_item || hardware_spec_item,
    }
    Object.assign(this.hardwareSpecData, specData)
    this.loading = false
  }

  // 初始化与虚拟相机相关的参数
  initVirtualCameraSpecData(data: any = {}) {
    const virtualCamera_spec_item = [
      {
        spec_type: 1,
        // 规格名
        spec_name: '',
        // 规格值
        spec_value: '',
      },
    ]
    const specData: any = {
      virtualCamera_spec_item:
          data.virtualCamera_spec_item || virtualCamera_spec_item,
    }
    Object.assign(this.virtualCameraSpecData, specData)
    this.loading = false
  }

  // 初始化
  initData() {
    if (!this.id) {
      apiDeviceNumber({}).then((resp: any) => {
        console.log('获取新增设备的编号', resp)
        this.form.device_number = resp[0].device_number
      })
    }
  }

  created() {
    this.getDeviceCategoryList()
    this.id = this.$route.query.id
    if (this.id) {
      this.getDeviceDetail()
      this.initData()
      return
    }
    this.initHardwareSpecData()
    this.initVirtualCameraSpecData()
    this.initData()
  }
}
